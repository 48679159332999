import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Logo from '../../assets/fortressLogo.svg'
import { Link } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import theme from '@/utils/theme'

const Wrapper = styled.div`
  padding: 50px 20px;
  min-height: 500px;
  position: relative;
  z-index: 10;
  font-size: ${({ theme }) => theme.fontSize.semiText};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    padding: 6% 8%;
    font-size: ${({ theme }) => theme.fontSize.text};
  }
`

const Content = styled.div``

const LogoStyle = styled.div`
  display: flex;
  place-items: center;
  position: relative;

  a {
    display: contents;
  }
`

const Sections = styled.div`
  flex-flow: wrap;
  flex-direction: row;
  display: flex;
  padding-block: 50px;

  a {
    inline-size: fit-content;
    height: max-content;
  }
`

const Section = styled.div`
  max-width: 600px;
  min-width: 200px;
  display: flex;
  padding-right: 6%;
  margin-bottom: 20px;

  :first-of-type {
    padding-left: 0;
  }

  a {
    inline-size: fit-content;
    margin: 8px;
  }

  flex-direction: column;
`

const SocialLinks = styled.div`
  //position: absolute;
  //display: flex;
  a {
    display: contents;
  }

  img {
    margin-left: 15px;

    width: 30px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    right: 10%;
    top: 200px;
  }
`

const Logos = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
`

const PolicyLinks = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  display: flex;

  a {
    color: ${({ theme }) => theme.colors.darkGray};
    margin-left: 10px;


    @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
      margin-left: 30px;
    }
`

type socialLinksGraphqlData = {
  icon: { file: { url: string } }
  url: string
}

export const Footer: React.FC = () => {
  const {
    allContentfulFooterSection: { nodes },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "footerBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulFooterSection {
        nodes {
          mainLinks {
            text
            url
          }
          socialLinks {
            icon {
              file {
                url
              }
            }
            url
          }
          policyLinks {
            ... on ContentfulLink {
              text
              url
            }
            ... on ContentfulText {
              text
            }
          }
        }
      }
    }
  `)

  const [result, setResult] = useState<[][]>([])
  const { mainLinks, socialLinks, policyLinks } = nodes[0]

  useEffect(() => {
    const size = 4
    const results = []
    for (let i = 0; i < mainLinks.length; i += size) {
      results.push(mainLinks.slice(i, i + size))
    }

    setResult(results)
  }, [])

  const Links = result.map((item) => (
    <Section>
      {item.map((item: { url: string; text: string }) => (
        <Link to={item.url}>{item.text}</Link>
      ))}
    </Section>
  ))

  const socialLinksElements = socialLinks.map(
    (item: socialLinksGraphqlData) => (
      <a href={item.url}>
        <img src={item.icon.file.url} alt="icon" />
      </a>
    )
  )

  return (
    <BackgroundImageWrapper
      image={backgroundImage.childImageSharp.fluid}
      backgroundColor={theme.colors.lightGray}
    >
      <Wrapper>
        <Content>
          <Logos>
            <LogoStyle>
              <Link to="/">
                <img src={Logo} alt={'logo'} />
              </Link>
            </LogoStyle>
            <SocialLinks>{socialLinksElements}</SocialLinks>
          </Logos>
          <Sections
            data-sal="slide-down"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="easeOutQuad"
          >
            {Links}
          </Sections>
          <PolicyLinks>
            <span>{policyLinks[0].text}</span>
            <Link to={policyLinks[1].url}>{policyLinks[1].text}</Link>
            <Link to={policyLinks[2].url}>{policyLinks[2].text}</Link>
          </PolicyLinks>
        </Content>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}
