import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import './navbar.scss'
import { useWindowSize } from '../../hooks'
import { Button } from '../StyledComponents/Button'
import Logo from '../../assets/fortressLogo.svg'
import { ModalContext } from '@/components/layout'

interface GraphqlProps {
  allContentfulNavigation: {
    nodes: {
      blueButton: {
        text: string
      }
      whiteButton: {
        text: string
      }
      links: {
        text: string
        url: string
        dropdownLinks: {
          text: string
          url: string
        }[]
      }[]
    }[]
  }
}

export const Navigation: React.FC = () => {
  const {
    allContentfulNavigation: { nodes },
  }: GraphqlProps = useStaticQuery(graphql`
    {
      allContentfulNavigation {
        nodes {
          blueButton {
            text
          }
          whiteButton {
            text
          }
          links {
            text
            url
            dropdownLinks {
              text
              url
            }
          }
        }
      }
    }
  `)

  const { blueButton, whiteButton, links } = nodes[0]

  const [scrolled, setScrolled] = useState(false)
  const { width } = useWindowSize()
  const isMobile = width < 1225
  const toggleModal = useContext(ModalContext)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 25) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const fortressLogo = (
    <LogoStyle>
      <Link
        to="/"
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal-easing="easeOutQuad"
      >
        <img src={Logo} alt={'logo'} width={isMobile ? 130 : 173} />
      </Link>
    </LogoStyle>
  )

  const navigationItems = links.map(
    ({ text, url, dropdownLinks }, key) => (
      <LinkStyle key={key}>
        {url === '/' ? (
          <a href="javascript:undefined">{text}</a>
        ) : (
          <Link to={url}>{text}</Link>
        )}
        {dropdownLinks && (
          <Dropdown>
            <DropdownContent>
              {dropdownLinks?.map(({ text, url }) => (
                <Link to={url}>{text}</Link>
              ))}
            </DropdownContent>
          </Dropdown>
        )}
      </LinkStyle>
    )
  )

  return (
    <>
      <Space display={isMobile ? 'none' : ''} />
      <Wrapper scrolled={scrolled} isMobile={isMobile}>
        {fortressLogo}
        {width < 1225 && width > 340 && (
          <Button
            onClick={toggleModal as () => void}
            style={{
              height: 28,
              width: 90,
              fontSize: 13,
              padding: 0,
              position: 'absolute',
              right: 62,
            }}
            primary
          >
            Get a Demo
          </Button>
        )}
        <NavigationLinksContainer
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="easeOutQuad"
          display={width < 1225 ? 'none' : 'flex'}
        >
          {navigationItems}
          <Buttons>
            <Button
              onClick={toggleModal as () => void}
              primary
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-delay="500"
              data-sal-easing="easeOutQuad"
            >
              {blueButton.text}
            </Button>
            <StyledButton
              data-sal="slide-left"
              data-sal-duration="1000"
              data-sal-delay="500"
              data-sal-easing="easeOutQuad"
            >
              {whiteButton.text}
            </StyledButton>
          </Buttons>
        </NavigationLinksContainer>
      </Wrapper>
    </>
  )
}

const Space = styled.div<{ display: string }>`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 30px;
  display: ${({ display }) => display}; ;
`

const Wrapper = styled.div<{ scrolled?: boolean; isMobile: boolean }>`
  display: flex;
  z-index: 100;
  top: 0;
  position: sticky;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding-inline: 8%;
  padding-block: ${({ scrolled, isMobile }) =>
    scrolled || isMobile ? '10px 10px' : '0 30px'};
  transition: padding 0.3s ease;

  ${({ scrolled }) =>
    scrolled &&
    css`
      box-shadow: 0 11px 11px -11px rgb(198, 198, 198);
      transition: all 0.3s ease;
    `}
`
const Dropdown = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  padding-top: 5px;
  left: 0;
`

const DropdownContent = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 3px;
  box-shadow: 0 2px 14px 0 rgba(41, 40, 73, 0.15);
  z-index: 1;

  a {
    width: max-content;
    width: -moz-max-content;

    :first-of-type {
      margin-top: 25px;
    }
    :last-of-type {
      margin-bottom: 25px;
    }
    margin: 12px 25px;
    //text-decoration: none;
    display: inline-block;
  }
`

const LinkStyle = styled.div`
  position: relative;
  display: inline-block;
  margin-inline: 20px;
  min-inline-size: max-content;
  place-self: center;
  font-size: ${({ theme }) => theme.fontSize.text};

  &:hover ${Dropdown} {
    display: block;
  }
`

const NavigationLinksContainer = styled.div<{ display: string }>`
  display: ${({ display }) => display};
  width: 100%;
  margin-left: 30px;
`

const Buttons = styled.div`
  text-align: right;
  width: 100%;
`
const LogoStyle = styled.div`
  display: flex;
  place-items: center;
`

const StyledButton = styled(Button)`
  border: solid 1px #353a55;
  margin: 0 0 0 25px;
`
