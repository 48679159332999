import React, {
  createContext,
  ReactChild,
  ReactChildren,
  useState,
} from 'react'
import { ThemeProvider } from 'styled-components'
import theme from '../utils/theme'
import GlobalStyle from '../utils/globalStyles'
import '../static/fonts/fonts.css'
import ModalContactForm from '@/components/ContactForm/ModalContactForm'
import Seo from '@/components/SEO/SEO'
import { MobileMenu } from '@/components/Navigation/MobileMenu'
import { Navigation } from '@/components/Navigation'

type Props = {
  title?: string
  children: ReactChild | ReactChildren | JSX.Element[]
}

export const ModalContext = createContext({})

const Layout: React.FC<Props> = ({ children, title }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ModalContext.Provider value={setIsModalOpen}>
        <Seo title={title} />
        <main
          style={{
            height: '100%',
          }}
        >
          <MobileMenu />
          <Navigation />
          {children}
        </main>
        <ModalContactForm toggle={toggle} isOpen={isModalOpen} />
      </ModalContext.Provider>
    </ThemeProvider>
  )
}

export default Layout
