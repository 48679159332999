import React, { useContext, useEffect, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Button } from '../StyledComponents/Button'
import { ModalContext } from '@/components/layout'
import { useWindowSize } from '@/hooks'

interface GraphqlProps {
  allContentfulNavigation: {
    nodes: {
      blueButton: {
        text: string
      }
      whiteButton: {
        text: string
      }
      links: {
        text: string
        url: string
        dropdownLinks: {
          text: string
          url: string
        }[]
      }[]
    }[]
  }
}

export const MobileMenu: React.FC = () => {
  const {
    allContentfulNavigation: { nodes },
  }: GraphqlProps = useStaticQuery(graphql`
    {
      allContentfulNavigation {
        nodes {
          blueButton {
            text
          }
          whiteButton {
            text
          }
          links {
            text
            url
            dropdownLinks {
              text
              url
            }
          }
        }
      }
    }
  `)
  const { width } = useWindowSize()
  const { blueButton, whiteButton, links } = nodes[0]

  const toggleModal = useContext(ModalContext)
  const [isOpen, setIsOpen] = useState(false)
  const [y, setY] = useState<number | undefined>(0)

  useEffect(() => {
    const elmnt = document.querySelector('.dropdown')
    const y = elmnt?.scrollHeight
    setY(y)
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const navigationItems = links.map(
    ({ text, url, dropdownLinks }, key) => (
      <>
        <LinkStyle key={key}>
          {url === '/' ? (
            <a onClick={toggle}>{text}</a>
          ) : (
            <Link to={url}>{text}</Link>
          )}
          {dropdownLinks && (
            <Dropdown
              isOpen={isOpen}
              height={isOpen ? `${y && y + 100}px` : '0px'}
            >
              <DropdownContent className={'dropdown'}>
                {dropdownLinks?.map(({ text, url }) => (
                  <Link to={url}>{text}</Link>
                ))}
              </DropdownContent>
            </Dropdown>
          )}
        </LinkStyle>
      </>
    )
  )

  return width < 1225 ? (
    <>
      <Menu right>
        {navigationItems}
        <Buttons>
          <StyledButtonPrimary onClick={toggleModal as () => void} primary>
            {blueButton.text}
          </StyledButtonPrimary>
          <StyledButton>{whiteButton.text}</StyledButton>
        </Buttons>
      </Menu>
    </>
  ) : (
    <></>
  )
}

const Dropdown = styled.div<{ height: string; isOpen: boolean }>`
  max-height: ${({ height }) => height};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;

  a {
    width: max-content;
    width: -moz-max-content;

    :first-of-type {
      margin-top: 25px;
    }
    :last-of-type {
      margin-bottom: 25px;
    }
    margin: 5px 0 0 10px;
    //text-decoration: none;
  }
`

const LinkStyle = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 10px;
  outline: none;
  min-inline-size: max-content;
`

const Buttons = styled.div`
  width: 100%;
  text-align: center;

  :focus {
    outline: none;
  }
`

const StyledButtonPrimary = styled(Button)`
  margin: 20px 0;

  width: 180px;

  padding-inline: 20px;
`

const StyledButton = styled(Button)`
  width: 180px;
  padding-inline: 10px;
`
